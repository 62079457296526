<template>
  <div class="news-page">
    <el-button type="primary" @click="handleAdd">添加新闻</el-button>
    <div class="news-list">
      <div class="right">
        <div v-if="newList.length > 0" :class="['news-item', activeId === item.id ? 'active' : '']" v-for="item of newList" :key="item.id" @click="handleClick(item.id)">
          <div class="date">
            <div class="day">{{item.date.slice(8)}}</div>
            <div class="month">{{item.date.slice(2, 7)}}</div>
          </div>
          <div class="msg">
            <div class="title">{{ item.title }}</div>
            <div class="detail">{{ item.message }}</div>
          </div>
          <div style="display: flex;align-items: center;">
            <el-button type="primary" icon="el-icon-edit" @click="handleUpdate($event, item)" />
            <el-popconfirm title="是否删除" @confirm="handleDelete(item.id)">
              <el-button @click.stop="() => {}" slot="reference" type="danger" icon="el-icon-delete" style="margin-left: 8px;" />
            </el-popconfirm>
            
          </div>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="visible" title="新闻">
      <el-form :form="newsForm">
        <el-form-item>
          <el-input v-model="newsForm.title" type="text" placeholder="新闻标题" />
        </el-form-item>
        <el-form-item>
          <el-input v-model="newsForm.date" type="text" placeholder="YYYY-MM-DD" />
        </el-form-item>
        <el-form-item>
          <el-input v-model="newsForm.message" type="textarea" :rows="4" placeholder="新闻内容"/>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false">取 消</el-button>
        <el-button type="primary" @click="handleSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import axios from 'axios'
  export default {
    data() {
      return {
        visible: false,
        activeId: 0,
        status: '',
        newsForm: {
          title: '',
          message: '',
          date: ''
        },
        newList: []
      }
    },
    mounted() {
      this.getNewsList()
    },
    methods: {
      handleClick(id) {
        if (this.activeId === id) {
          this.activeId = 0
        } else {
          this.activeId = id
        }
      },
      handleAdd() {
        this.status = 'add'
        this.visible = true
      },
      handleSubmit() {
        const { title, message, date } = this.newsForm
        if (title.length === 0) {
          this.$notify.warning('请输入新闻标题')
          return
        }
        if (message.length === 0) {
          this.$notify.warning('请输入新闻内容')
          return
        }
        if (date.length === 0) {
          this.$notify.warning('请输入新闻日期')
          return
        }
        if (this.status === 'add') {
          axios.post('/api/news', {...this.newsForm}).then(data => {
            if (data.data === 'success') {
              this.$notify.success('添加成功')
              this.visible = false
              this.getNewsList()
            } else {
              this.$notify.error('添加失败')
            }
          })
        } else {
          axios.put('/api/news', {...this.newsForm}).then(data => {
            if (data.data === 'success') {
              this.$notify.success('修改成功')
              this.visible = false
              this.getNewsList()
            } else {
              this.$notify.error('修改失败')
            }
          })
        }
      },
      handleUpdate(event, newsObj) {
        event.stopPropagation()
        this.status = 'update'
        this.newsForm = {...newsObj}
        this.visible = true
      },
      handleDelete(id) {
        axios.delete(`/api/news/${id}`).then(data => {
          if (data.data === 'success') {
            this.$notify.success('删除成功')
            this.getNewsList()
          } else {
            this.$notify.error('删除失败')
          }
        })
      },
      getNewsList() {
        axios.get('/api/news').then(data => {
          this.newList = data.data
        })
      }
    }
  }
</script>

<style lang="less">
  .news-page {
    .news-list {
      display: flex;
      padding: 0 16px;
      .right {
        width: 100%;
      
        & > .news-item:not(:first-child) {
          margin-top: 8px;
        }
      
        .news-item {
          display: flex;
          border-bottom: 1px solid #d3d3d3;
          padding-bottom: 12px;
          cursor: pointer;
        
          &.active {
            .detail {
              white-space: normal;
            }
          }
          
          .date {
            color: #666666;
            text-align: center;
            margin: 3px 48px 3px 0;
            .day {
              font-size: 30px;
              font-weight: 600;
            }
            .month {
              white-space: nowrap;
              font-size: 18px;
            }
          }
        }
      
        .msg {
          color: #333333;
          width: calc(100% - 64px - 48px - 150px);
          .title {
            font-size: 18px;
            letter-spacing: 3px;
            padding: 8px 0;
            font-weight: 600;
          }
          .detail {
            font-size: 15px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-top: 4px;
          }
        }
      }
    }
  }
</style>
